import { computed } from "vue";
import { useStore } from "vuex";

export default () => {
  const store = useStore();

  const plans = computed(() => store.state.plans);

  const currentPlan = computed(() => {
    return store.state.currentPlan;
  });

  const coupon = computed(() => {
    return store.state.coupon;
  });

  const changePlan = (item) => {
    store.dispatch("CHANGE_PLAN", item)
  }

  return { plans, currentPlan, changePlan, coupon };
};
