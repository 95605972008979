<template>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full">
      <div>
        <figure>
          <h1 class="text-6xl text-gray-900 font-bold">Adbox</h1>
        </figure>
        <h2
          class="mt-6 text-center text-3xl leading-9 font-semibold text-gray-900"
        >
          Reset Password
        </h2>
      </div>

      <div class="form mt-8">
        <input type="hidden" name="remember" value="true" />
        <div>
          <div class="mb-4">
            <Label for="email">New Password</Label>
            <Input
              id="email"
              aria-label="Email address"
              name="email"
              type="password"
              required
              placeholder="************"
              v-model="password"
            />
          </div>
        </div>

        <div>
          <div class="mb-4">
            <Label for="email">Confirm New Password</Label>
            <Input
              id="email"
              aria-label="Email address"
              name="email"
              type="password"
              required
              placeholder="************"
              v-model="verifyPassword"
            />
          </div>
        </div>

        <div class="w-full flex justify-center items-center py-2" v-if="error">
          <div
            class="w-5 h-5 bg-red-600 grid place-content-center mr-2 rounded-md"
          >
            <Icon name="Close" class="w-3 h-3 text-white" />
          </div>
          <p class="text-red-600 font-medium leading-none">
            {{ error }}
          </p>
        </div>

        <div class="mt-6">
          <Button @click="changePassword" block>
            <template v-slot:icon>
              <Icon name="Key" class="w-6 h-6" />
            </template>
            Reset Password
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button";
import Input from "@/components/UI/Input";
import Label from "@/components/UI/Label";
import Icon from "@/components/UI/Icon";

import axios from "axios";

const ADBOX_API_URL = process.env.VUE_APP_ADBOX_API_URL;

export default {
  name: "SignIn",
  components: { Button, Input, Label, Icon },
  data() {
    return {
      verifyPassword: "",
      password: "",
      error: "",
    };
  },
  methods: {
    async changePassword() {
      const token = this.$route.params.token;

      const password = this.verifyPassword;

      if (this.password == this.verifyPassword) {
        await axios({
          method: "POST",
          url: `${ADBOX_API_URL}/forgot-password`,
          data: {
            token,
            password,
          },
        });

        this.$router.push({ path: "/" });
      } else {
        this.error = "Passwords do not match";
      }
    },
  },
};
</script>
