<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <component :is="name" />
  </svg>
</template>

<script>
import Grid from "@/icons/Grid";
import Heart from "@/icons/Heart";
import Search from "@/icons/Search";
import CreditCard from "@/icons/CreditCard";
import Logout from "@/icons/Logout";
import Setting from "@/icons/Setting";
import Support from "@/icons/Support";
import Adjustments from "@/icons/Adjustments";
import Close from "@/icons/Close";
import ArrowNarrowLeft from "@/icons/ArrowNarrowLeft"
import ArrowNarrowRight from "@/icons/ArrowNarrowRight"
import Selector from "@/icons/Selector"
import Check from "@/icons/Check"
import CursorClick from "@/icons/CursorClick"
import Sparkles from "@/icons/Sparkles"
import BadgeCheck from "@/icons/BadgeCheck"
import Refresh from "@/icons/Refresh"
import PaperAirplane from "@/icons/PaperAirplane"
import Key from "@/icons/Key"
import Mail from "@/icons/Mail"
import XCircle from "@/icons/XCircle"
import Exclamation from "@/icons/Exclamation"
import User from "@/icons/User"
export default {
  components: {
    Exclamation,
    XCircle,
    Mail,
    Key,
    PaperAirplane,
    Refresh,
    BadgeCheck,
    Sparkles,
    CursorClick,
    Check,
    Selector,
    ArrowNarrowLeft,
    ArrowNarrowRight,
    Close,
    Grid,
    Heart,
    Search,
    CreditCard,
    Logout,
    Setting,
    Support,
    Adjustments,
    User
  },
  props: ["name"],
};
</script>

<style></style>
