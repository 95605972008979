<template>
  <div class="w-screen min-h-screen flex flex-col items-center">
    <div
      v-show="pendingPay"
      class="grid p-8 text-center justify-center items-center content-center my-auto"
    >
      <Icon name="Exclamation" class="w-12 h-12 text-red-600 mx-auto mb-4" />
      <h2 class="text-4xl font-semibold mb-4">
        We are validating your payment
      </h2>
      <p class="mb-10 text-lg">
        If you don't get a welcome email, try to
        <router-link to="/" class="text-indigo-500 font-medium"
          >log in</router-link
        >
        later or contact support.
      </p>
      <p v-if="!errorLogin">Redirecting in {{ countDown }}</p>
      <p v-if="errorLogin">Contact Support</p>
    </div>
    <div
      v-show="!pendingPay"
      class="grid p-8 text-center justify-center items-center content-center my-auto"
    >
      <Icon name="BadgeCheck" class="w-12 h-12 text-green-600 mx-auto mb-4" />
      <h2 class="text-4xl font-semibold mb-4">
        🙌 Thanks for joining Adbox! 🎉
      </h2>
      <p class="mb-10 text-lg">
        You can start learning from the best ad inspiration now
      </p>
      <p class="text-lg">Redirecting...</p>
    </div>
    <div class="py-3" v-show="!pendingPay">
      <p>
        Please
        <a href="https://app.meetadbox.com" class="text-blue-600 font-medium"
          >click here</a
        >
        if you are not redirected within a few seconds
      </p>
    </div>
    <footer class="w-full bg-gray-900 text-white flex py-6 px-20">
      <p class="mr-auto">
        © 2022 Adbox, all rights reserved.
        <a href="mailto:hello@meetadbox.com" target="_blank"
          >hello@meetadbox.com</a
        >
      </p>
      <a
        href="https://meetadbox.com/terms"
        target="_blank"
        class="font-semibold text-green-500 mr-4"
        >Terms of Service</a
      >
      <a
        href="https://meetadbox.com/privacy"
        target="_blank"
        class="font-semibold text-green-500"
        >Privacy Policy</a
      >
    </footer>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import Icon from "@/components/UI/Icon";

export default {
  components: { Icon },
  setup() {
    const store = useStore();

    const pendingPay = computed(() => store.state.pendingPay);
    const errorLogin = computed(() => store.state.errorLogin);

    const countDown = ref(5);
    const counter = () => {
      setInterval(() => {
        if (countDown.value == 0) {
          countDown.value = 0;
        } else {
          countDown.value -= 1;
        }
      }, 1000);
    };
    onMounted(() => {
      counter();
    });
    return { pendingPay, countDown, errorLogin };
  }
};
</script>

<style></style>
