<template>
  <div class="min-h-screen">
    <div
      class="max-w-screen-xl px-3 sm:px-10 xl:px-2 mx-auto grid grid-cols-5 gap-x-20 items-start content-start bg-gray-50 text-left"
    >
      <header
        class="col-span-5 w-full flex justify-between py-4 mb-6 row-start-1"
      >
        <figure class="flex items-center">
          <figure class="title">
            <h1 class="title__ad text-5xl text-gray-900 font-bold">
              Ad
            </h1>
            <h1 class="title__box text-5xl text-gray-900 font-bold">
              box
            </h1>
          </figure>
        </figure>
        <div class="flex gap-5 items-center">
          <router-link to="/">
            <Button>Sign In</Button>
          </router-link>
        </div>
      </header>
    </div>
    <section
      v-if="plans.length && plans[0].period == 'month'"
      class="py-10 bg-gray-50 sm:py-1 lg:py-1"
    >
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <!--   <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center ">
          <h2
            class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl"
          >
            Get ahead of your competition
          </h2>
          <p
            class="max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600"
          >
            Join 1200+ of best advertising agencies and eCommerce brands
            boosting their ROAS and reducing their CPA
          </p>
        </div>
      </div>-->
        <div
          v-show="plans && plans.length > 0"
          class="flex min-h-full items-center justify-center py-0 px-4 sm:px-6 text-left"
        >
          <!--   <div class="relative bg-white rounded-md pb-3">
          <div class="p-6 md:py-10 md:px-7">
            <div
              class="flex text-left focus:text-indigo-500 inline-block px-4 py-2 pl-0"
            >
              <h3
                class="font-semibold text-sm text-gray-900 focus:text-indigo-500 bg-gray-200 rounded-full p-3 m-0"
              >
                <p>
                  Free
                </p>
              </h3>
            </div>

            <div>
              <p class="text-5xl font-semibold text-black text-left">
                $0
              </p>
            </div>

            <p class=" flex text-base text-gray-600">
              Per month
            </p>
            <br />

            <p class="flex font-semibold text-gray-900 text-base mt-1 mb-3">
              Ads from last year
            </p>
            <ul class="flex flex-col pt-2">
              <li class="inline-flex space-x-2 pb-2">
                <svg
                  class="flex-shrink-0 w-5 h-5 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="text-base font-medium text-gray-900">
                  30 FB Ad creatives
                </span>
              </li>
            </ul>
            <ul class="flex flex-col my-2">
              <li class="inline-flex space-x-2 pb-2">
                <svg
                  class="flex-shrink-0 w-5 h-5 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="text-base font-medium text-gray-900">
                  30 viral Tiktok Ads
                </span>
              </li>
            </ul>

            <div class="flex items-center justify-center pay_btn">
              <div class="absolute bottom-0 top-2 mt-6 mb-8 md:px-7">
                <router-link to="/signup">
                  <a
                    class="inline-flex items-center justify-center w-full px-12 py-5 mt-5 pa-5 font-semibold text-white transition-all duration-200 bg-gray-500  hover:bg-gray-400 rounded-md"
                    role="button"
                    @click="changePlan(freeItem)"
                  >
                    Unlock Free
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
 -->
          <div
            v-for="plan in plans"
            :key="plan.id"
            class="relative bg-white border-4 rounded-md pb-3"
            :class="plan.period == 'year' ? 'border-4 border-indigo-500 ' : ''"
          >
            <div class="p-6 md:py-10 md:px-7 text-left hover:indigo-500">
              <div
                class="flex hover:text-indigo-500 hover:indigo-500 focus:text-indigo-500 inline-block px-4 py-2 pl-0"
              >
                <div class="bg-gray-200 rounded-full p-3 m-0 hover:indigo-500">
                  <h3
                    class="text-sm font-semibold text-gray-900 hover:text-indigo-500 focus:text-indigo-500 "
                  >
                    <p>
                      {{ plan.name }}
                    </p>
                  </h3>
                </div>
              </div>

              <div class="flex" v-if="plan.use_discount">
                <p class=" text-5xl text-gray font-bold line-through">
                  <span class="text-gray-700"> ${{ plan.price }} </span>
                </p>
                &nbsp;&nbsp;
                <p class="text-5xl font-bold text-black">
                  <span class="text-black"> ${{ plan.discounted_price }} </span>
                </p>
              </div>
              <div v-else>
                <p class="flex text-5xl font-bold text-black">
                  ${{ plan.price }}
                </p>
              </div>

              <p class="flex text-base text-gray-600">
                {{ plan.extras }}
              </p>

              <ul
                v-for="(features, index) in plan.features"
                :key="index"
                class="flex flex-col my-2"
              >
                <p
                  v-if="index == 0 && plan.period == 'month'"
                  class="mt-3 flex font-semibold text-gray-900 text-left text-base mt-1 mb-4"
                >
                  Ads from last months
                </p>
                <p
                  v-if="index == 0 && plan.period == 'year'"
                  class="mt-3 flex font-semibold text-gray-900 text-left text-base mt-1 mb-4"
                >
                  Our Entire Ad Library
                </p>
                <li class="inline-flex items-center space-x-2 pb-2">
                  <svg
                    class="flex-shrink-0 w-5 h-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="text-base font-medium text-gray-900">
                    {{ features }}
                  </span>
                </li>
                <p
                  v-if="index == 2"
                  class="flex mt-8 font-semibold text-gray-900 text-center text-base my-6"
                >
                  Performance Resources
                </p>

                <p
                  v-if="index == 6"
                  class="flex font-semibold text-gray-900 text-center text-base my-6"
                >
                  ACCESS FOREVER
                </p>
              </ul>

              <div class="flex items-center justify-center my-5">
                <div class="absolute bottom-0 top-2 mt-6 mb-8 md:px-7">
                  <router-link to="/signup">
                    <a
                      @click="changePlan(plan)"
                      class="flex items-center justify-center w-full px-12 py-5 font-semibold text-white transition-all duration-200 bg-gray-900 rounded-md btn"
                      role="button"
                      >Unlock now</a
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import usePlans from "../components/Plans/usePlans";
import Button from "@/components/UI/Button";
// import Icon from "@/components/UI/Icon";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  components: { Button },
  setup() {
    const store = useStore();
    const plans = computed(() => store.state.plans);

    const currentAccount = computed(() =>
      store.state.currentAccount ? store.state.currentAccount : null
    );

    const freeItem = {
      id: "0000-0000-0000-0000",
      name: "free",
      price: 0,
    };

    return { ...usePlans(), currentAccount, freeItem, plans };
  },
};
</script>

<style scoped>
.btn {
  background-image: linear-gradient(90deg, #c026d3, #2563eb);
}
.btn:hover {
  background-image: linear-gradient(90deg, #ba55d3, #1f45fc);
}
.box:hover h3 {
  color: #3652d9;
}
.title {
  display: flex;
  justify-content: center;
  letter-spacing: -2px;
}
.title__box {
  letter-spacing: -2px;
  color: #333;
}
.title__ad {
  color: #3652d9;
}
</style>
