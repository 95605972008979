<template>
  <div class="min-h-fill">
    <div class="min-h-content">
      <div
        v-if="loadingAfterPayment"
        class="w-full h-full fixed top-0 bottom-0 left-0 right-0 z-50 bg-black bg-opacity-75 grid place-content-center"
      >
        <Icon
          name="Refresh"
          class="w-12 h-12 text-white animate-spin duration-1000"
        />
      </div>
      <div>
        <!--   <div
        v-show="currentPlan && currentPlan.name != 'free'"
        class="max-w-screen-xl px-3 sm:px-10 xl:px-2 mx-auto grid grid-cols-5 gap-x-20 items-start content-start bg-gray-50 text-left"
      >
        <header
          class="col-span-5 w-full flex justify-between py-4 mb-6 row-start-1"
        >
          <figure class="flex items-center">
            <figure class="title">
              <h1 class="title__ad text-5xl text-gray-900 font-bold">
                Ad
              </h1>
              <h1 class="title__box text-5xl text-gray-900 font-bold">
                box
              </h1>
            </figure>
          </figure>
          <router-link to="/">
            <Button>Sign In</Button>
          </router-link>
        </header>
      </div> -->
        <section class="bg-white">
          <div class="grid grid-cols-1 lg:grid-cols-2 h-screen">
            <div
              class="relative flex items-end px-4 pb-10 pt-60 sm:pb-16 md:justify-left lg:pb-24 bg-gray-50 sm:px-6 lg:px-8"
            >
              <div class="absolute inset-0">
                <img
                  class="object-cover h-full w-full"
                  src="@/assets/signup.png"
                  alt=""
                />
              </div>
              <div
                class="absolute inset-0 bg-gradient-to-t from-black to-transparent"
              ></div>

              <div class="relative">
                <div
                  class="w-full max-w-xl xl:w-full xl:mx-auto xl:pr-12 xl:max-w-xl"
                >
                  <h3 class="text-4xl font-bold text-white text-left">
                    See why 1200+ ad agencies
                    <br class="hidden xl:block" />
                    & brands trust us
                  </h3>
                  <ul
                    class="grid grid-cols-1 mt-10 sm:grid-cols-2 gap-x-8 gap-y-4"
                  >
                    <li class="flex items-center space-x-3">
                      <div
                        class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full"
                      >
                        <svg
                          class="w-3.5 h-3.5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-left font-medium text-white">
                        Top performing FB Ads
                      </span>
                    </li>
                    <li class="flex items-center space-x-3">
                      <div
                        class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full"
                      >
                        <svg
                          class="w-3.5 h-3.5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-left font-medium text-white">
                        Viral Tiktok Ads
                      </span>
                    </li>
                    <li class="flex items-center space-x-3">
                      <div
                        class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full"
                      >
                        <svg
                          class="w-3.5 h-3.5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-left font-medium text-white">
                        Spy the Top 3000 eCommerce brands
                      </span>
                    </li>
                    <li class="flex items-center space-x-3">
                      <div
                        class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full"
                      >
                        <svg
                          class="w-3.5 h-3.5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-left font-medium text-white">
                        Best ad hooks for your audience
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="flex items-center justify-center px-4 py-10 bg-white sm:px-6 lg:px-8 sm:py-16 lg:py-24"
            >
              <div class="xl:w-full xl:max-w-md 2xl:max-w-md xl:mx-auto">
                <h2
                  class="text-3xl font-bold leading-tight text-black sm:text-4xl text-left"
                >
                  You’re one step away!
                </h2>
                <p class="mt-2 mb-10 text-base text-gray-600 text-left">
                  Already have an account?
                  <a
                    :href="signin_url"
                    title=""
                    class="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
                    >Login</a
                  >
                </p>

                <div class="space-y-5 text-left">
                  <div>
                    <Label
                      for="fullName"
                      class="text-base font-medium text-gray-900"
                      >Full Name</Label
                    >

                    <Input
                      iconName="User"
                      id="fullName"
                      aria-label="Full Name"
                      name="full_name"
                      type="text"
                      required
                      placeholder="Name"
                      v-model="form.full_name"
                      @change="handleInput('full_name')"
                      class="mt-2"
                    />
                    <p class="error">{{ errors["full_name"] }}</p>
                  </div>

                  <div>
                    <label for="" class="text-base font-medium text-gray-900">
                      Email address
                    </label>
                    <div
                      class="mt-2.5 relative text-gray-400 focus-within:text-gray-600"
                    >
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                      >
                        <svg
                          class="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                          />
                        </svg>
                      </div>

                      <input
                        type="email"
                        name=""
                        placeholder="Work email"
                        v-model="form.email"
                        @change="handleInput('email')"
                        class="block w-full mt-2 py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                      />
                    </div>
                    <p class="error">{{ errors["email"] }}</p>
                  </div>

                  <div>
                    <label for="" class="text-base font-medium text-gray-900">
                      Password
                    </label>
                    <div
                      class="mt-2.5 relative text-gray-400 focus-within:text-gray-600"
                    >
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                      >
                        <svg
                          class="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                      </div>

                      <input
                        type="password"
                        name=""
                        id=""
                        placeholder="Password"
                        v-model="form.password"
                        @change="handleInput('password')"
                        class="block w-full mt-2 py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                      />
                    </div>
                    <p class="error">{{ errors["password"] }}</p>
                  </div>
                  <div>
                    <Label
                      for="businessType"
                      class="text-base font-medium text-gray-900"
                    >
                      Business type
                    </Label>

                    <div class="flex">
                      <div class="flex items-center mr-4">
                        <input
                          id="agency"
                          type="radio"
                          value="agency"
                          name="inline-radio-group"
                          v-model="form.type"
                          @change="handleInput('type')"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="agency"
                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Agency</label
                        >
                      </div>
                      <div class="flex items-center mr-4">
                        <input
                          id="ecommerce"
                          type="radio"
                          value="ecommerce"
                          name="inline-radio-group"
                          v-model="form.type"
                          @change="handleInput('type')"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="ecommerce"
                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Ecommerce</label
                        >
                      </div>
                      <div class="flex items-center mr-4">
                        <input
                          id="other"
                          type="radio"
                          value="other"
                          name="inline-radio-group"
                          v-model="form.type"
                          @change="handleInput('type')"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="other"
                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Other</label
                        >
                      </div>
                    </div>
                    <p class="error">{{ errors["type"] }}</p>
                  </div>
                  <div class="my-0 py-0">
                    <ColoredButton @click="validateAccountFields" block>
                      Access Adbox
                    </ColoredButton>
                  </div>
                  <div class="checkout-container w-full"></div>
                </div>

                <div class="mt-3 space-y-3">
                  <p class="mt-5 text-sm text-gray-600 text-left">
                    By signing up, you agree to our
                    <a
                      href="https://www.meetadbox.com/privacy"
                      title=""
                      class="text-blue-600 transition-all duration-200 hover:underline hover:text-blue-700"
                      >Privacy Policy</a
                    >
                    &
                    <a
                      href="https://www.meetadbox.com/terms"
                      title=""
                      class="text-blue-600 transition-all duration-200 hover:underline hover:text-blue-700"
                      >Terms of Service</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!--   <div v-else class="max-w-screen-xl h-screen px-3 sm:px-10 xl:px-2 mx-auto grid grid-cols-5 gap-x-20 items-start content-start bg-gray-50 text-left">
        <div
          class="col-span-5 lg:col-span-3 w-full lg:pl-8 row-start-3 lg:row-auto"
        >
          <div class="mb-4">
            <h2 class="text-xl font-medium">
              Get Started
            </h2>
          </div>
          <div>
            <input type="hidden" name="remember" value="true" />
            <div class="mb-4 grid grid-flow-col gap-4">
              <div>
                <Label for="firstName">First name</Label>
                <Input
                  id="firstName"
                  aria-label="First name"
                  name="first_name"
                  type="text"
                  required
                  placeholder="First name"
                  v-model="form.first_name"
                  @change="handleInput('first_name')"
                />
                <p class="error">{{ errors["first_name"] }}</p>
              </div>
              <div>
                <Label for="lastName">Last name</Label>
                <Input
                  id="lastName"
                  aria-label="Last name"
                  name="last_name"
                  type="text"
                  placeholder="Last name"
                  v-model="form.last_name"
                />
              </div>
            </div>
            <div class="mb-4">
              <Label for="company">Business type</Label>
              <Select
                v-model="form.type"
                :options="userTypes"
                placeholder="Type"
              ></Select>
            </div>
            <div class="mb-4">
              <Label for="email">Email address</Label>
              <Input
                id="email"
                aria-label="Email address"
                name="email"
                type="email"
                required
                placeholder="Email address"
                v-model="form.email"
                @change="handleInput('email')"
              />
              <p class="error">{{ errors["email"] }}</p>
            </div>
            <div class="mb-4 grid grid-flow-col gap-4">
              <div>
                <Label for="password">Password</Label>
                <Input
                  id="password"
                  aria-label="Password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  v-model="form.password"
                  @change="handleInput('password')"
                />
                <p class="error">{{ errors["password"] }}</p>
              </div>
              <div>
                <Label for="retype_password">Confirm Password</Label>
                <Input
                  id="retype_password"
                  aria-label="Password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  v-model="form.retype_password"
                  @change="handleInput('retype_password')"
                />
                <p class="error">{{ errors["retype_password"] }}</p>
              </div>
            </div>
            <div class="mt-6 flex flex-col items-start justify-between">
              <div class="flex items-center">
                <label
                  for="accepted_the"
                  class="block text-sm leading-5 text-gray-900 text-left"
                >
                  By signing up, you agree to our
                  <a
                    href="https://meetadbox.com/terms"
                    target="_blank"
                    class="text-indigo-600"
                    >Terms of Service</a
                  >
                  and
                  <a
                    href="https://meetadbox.com/privacy"
                    target="_blank"
                    class="text-indigo-600"
                    >Privacy Policy</a
                  >
                </label>
              </div>
              <p class="error">{{ errors["accepted_the"] }}</p>
            </div>
         

            <div v-if="currentPlan && currentPlan.name !== 'free'" class="mt-6">
              <Button @click="validateAccountFields" block>
                <template v-slot:icon>
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    ></path>
                  </svg>
                </template>
                Continue to payment
              </Button>
            </div>
            <div v-else class="mt-6">
              <Button @click="validateAccountFields" block>
                Access Adbox
              </Button>
            </div>
          </div>
          <div class="checkout-container w-full"></div>
        </div>
        <div
          class="static lg:sticky top-0 col-span-5 lg:col-span-2 w-full lg:pr-8 row-start-2 lg:row-auto mb-6 lg:mb-0"
          v-if="currentPlan && currentPlan.name !== 'free'"
        >
          <h2 class="text-xl font-medium">
            {{ currentPlan ? `Summary` : `Plans` }}
          </h2>
          <p class="error">{{ errors["current_plan"] }}</p>
          <Plans @click="getTarget" class="mt-4" />
        </div>
      </div> -->

      <div
        class="w-full fixed top-0 flex justify-center pt-2 px-2"
        v-show="error"
      >
        <div
          class="max-w-xl flex items-center bg-red-500 py-2 px-4 text-white rounded-md"
        >
          <div class="bg-red-600 p-1 mr-4 rounded-md">
            <Icon name="Close" class="w-5 h-5" />
          </div>
          <p class="text-left font-medium leading-tight">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
//import { useRouter } from "vue-router";
////import Button from "@/components/UI/Button";
import Input from "@/components/UI/Input";
//import Select from "@/components/UI/Select/index";
import Label from "@/components/UI/Label";
//import Plans from "@/components/Plans/index";
import Icon from "@/components/UI/Icon";
import ColoredButton from "@/components/UI/ColoredButton";
import { computed, ref, onBeforeMount, onMounted } from "vue";

export default {
  name: "SignUp",
  components: {
    //Button,
    Input,
    Label,
    // Select,
    //Plans,
    Icon,
    ColoredButton,
  },
  setup() {
    const store = useStore();
    // const router = useRouter();
    // const plann = ref();
    let source = null;
    //let annual, monthly;
    onBeforeMount(async () => {
      //if (!currentPlan.value && !window.location.search)
      // router.push("/pricing");
    });

    onMounted(async () => {
      /*
         if (window.location.search) {
        const url = window.location.search;
        const urlParams = new URLSearchParams(url);
        const plan = urlParams.get("plan");
        source = urlParams.get("source");

        const free = {
          id: "0000-0000-0000-0000",
          name: "free",
          price: 0,
          per: "year",
          currency: "USD",
          taxes: "includes on price",
        };

        const res = await store.dispatch("GET_MEMBERSHIPS", { period: plan });
        plann.value = res.data;

        const plans = computed(() => store.state.plans);
        if (plans.value) {
          if (plans.value && plans.value[0].period === "year") {
            annual = plans.value[0];
          } else if (plans.value && plans.value[0].period === "month") {
            monthly = plans.value[0];
          }
        }

        switch (plan) {
          case "annual":
            store.dispatch("CHANGE_PLAN", annual);
            break;
          case "monthly":
            store.dispatch("CHANGE_PLAN", monthly);
            break;
          case "free":
            store.dispatch("CHANGE_PLAN", free);
            break;
          default:
            return "";
        }
      }*/
    });

    if (free) {
      const item = {
        id: "0000-0000-0000-0000",
        name: "free",
        price: 0,
        per: "year",
        currency: "USD",
        taxes: "includes on price",
      };
      isUserFree.value = true;
      store.dispatch("CHANGE_PLAN", item);
    }

    const userTypes = [
      { label: "Agency", value: "agency" },
      { label: "Ecommerce", value: "ecommerce" },
      { label: "Other", value: "other" },
    ];

    // if (window.location.search) {
    //   const url = window.location.search;
    //   const urlParams = new URLSearchParams(url);
    //   const plan = urlParams.get("plan");

    // if (plans.value) {
    //   annual = plans.value.find((item) => item.period === "year");
    //   monthly = plans.value.find((item) => item.period === "month");
    // }

    // const free = {
    //   id: "0000-0000-0000-0000",
    //   name: "free",
    //   price: 0,
    //   per: "year",
    //   currency: "USD",
    //   taxes: "includes on price",
    // };
    // switch (plan) {
    //   case "annual":
    //     store.dispatch("CHANGE_PLAN", annual);
    //     break;
    //   case "monthly":
    //     store.dispatch("CHANGE_PLAN", monthly);
    //     break;
    //   case "free":
    //     store.dispatch("CHANGE_PLAN", free);
    //     break;
    //   default:
    //     return "";
    // }
    // }

    const isUserFree = ref(false);

    const error = ref(null);

    const errors = ref({});

    const currentStep = ref(1);

    const form = ref({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      retype_password: "",
      type: "",
      value: "",
      user_role: "",
    });

    const businessType = ref({
      eccommerce: null,
      other: null,
      agency: null,
    });

    const loadingAfterPayment = computed(() => store.state.loadingAfterPayment);

    const type = computed(() =>
      userTypes.find((item) => item.value === form.value.type)
    );
    const plan = {
      discounted_price: "297",
      extras: "50% OFF",
      features: [
        "3500+ top FB Ad creatives",
        "4000+ Viral TikTok Ads",
        "Updated monthly",
        "Spy the top 3000 brands",
        "200+ high-performing hooks",
        "15 ultra-high performing ad examples to start testing",
        "200+ UGC creators directory",
      ],
      name: "Annual Membership",
      paddle: { id: 636842, vendor: 26610 },
      paddle_plan_id: 636842,
      period: "year",
      price: "588",
      use_discount: true,
    };
    store.dispatch("CHANGE_PLAN", plan);
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const free = urlParams.get("free");
    const annual = urlParams.get("plan");
    const currentPlan = computed(() => {
      handleInput("current_plan");
      return store.state.currentPlan;
    });

    const nextStep = () => {
      currentStep.value += 1;
    };

    const handleInput = (input) => {
      delete errors.value[input];
    };

    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
      return re.test(email);
    };

    const validateAccountFieldsFree = async () => {
      console.log("value", currentPlan);
      if (!currentPlan.value) {
        errors.value.current_plan = "Please select a plan";
      }

      nextStep();

      form.value.user_role = currentPlan.value.name;
      form.value.period = currentPlan.value.period;
      store.commit("SET_CURRENT_ACCOUNT", form.value);
      const reslut = await store.dispatch("SIGN_UP", form.value);
      if (
        reslut.data &&
        reslut.data.data &&
        reslut.data.data.code == "AlreadyExists"
      ) {
        errors.value.email = "Email is Already Registered";
      } else {
        store.dispatch("SET_PADDLE");
      }
    };

    if (annual) {
      try {
        validateAccountFieldsFree();
      } catch (e) {
        console.log("e", e);
      }
    }

    const validateAccountFields = async () => {
      if (!currentPlan.value) {
        errors.value.current_plan = "Please select a plan";
      }

      if (!form.value.full_name) {
        errors.value.full_name = "Please enter your full name";
      } else {
        form.value.first_name = form.value.full_name.split(" ")[0];
        form.value.last_name = form.value.full_name.split(" ")[1];
      }
      if (!validateEmail(form.value.email)) {
        errors.value.email = "The email address is incorrect";
      }
      if (!form.value.email) {
        errors.value.email = "Please enter an email address";
      }
      if (!form.value.password) {
        errors.value.password = "Please enter a password";
      }
      //if (form.value.password !== form.value.retype_password) {
      // errors.value.retype_password = "Passwords must match";
      //}
      if (Object.keys(errors.value).length !== 0) {
        return true;
      }

      nextStep();
      form.value.source = source;

      if (currentPlan.value.name === "free") {
        store.commit("SET_CURRENT_ACCOUNT", form.value);
        const result = await store.dispatch("SIGN_UP_FREE", form.value);

        if (result.data.data.code == "AlreadyExists") {
          errors.value.email = "Email is Already Registered";
        }
      } else {
        form.value.user_role = currentPlan.value.name;
        form.value.period = currentPlan.value.period;
        store.commit("SET_CURRENT_ACCOUNT", form.value);
        const reslut = await store.dispatch("SIGN_UP", form.value);
        if (
          reslut.data &&
          reslut.data.data &&
          reslut.data.data.code == "AlreadyExists"
        ) {
          errors.value.email = "Email is Already Registered";
        } else {
          store.dispatch("SET_PADDLE");
        }
      }
    };

    const validateFreeAccountFields = async () => {
      if (!currentPlan.value) {
        errors.value.current_plan = "Please select a plan";
      }
      if (!form.value.full_name) {
        errors.value.full_name = "Please enter your full name";
      } else {
        form.value.first_name = form.value.full_name.split(" ")[0];
        form.value.last_name = form.value.full_name.split(" ")[1];
      }

      if (!validateEmail(form.value.email)) {
        errors.value.email = "The email address is incorrect";
      }
      if (!form.value.email) {
        errors.value.email = "Please enter an email address";
      }
      if (!form.value.password) {
        errors.value.password = "Please enter a password";
      }
      if (!form.value.type) {
        errors.value.type = "Please choose a type";
      }

      if (Object.keys(errors.value).length !== 0) {
        return true;
      }

      nextStep();
      form.value.source = source;

      store.commit("SET_CURRENT_ACCOUNT", form.value);
      const result = await store.dispatch("SIGN_UP_FREE", form.value);
      if (result.data.data.code == "AlreadyExists") {
        //   errors.value.email = "Email is Already Registered";
      }
    };

    const getTarget = () => {
      if (currentPlan.value.name === "free") {
        isUserFree.value = !isUserFree.value;
      } else {
        isUserFree.value = false;
      }
    };

    const setError = (data) => {
      error.value = data;
      setTimeout(() => {
        error.value = null;
      }, 4000);
    };
    const signin_url = process.env.VUE_APP_ADBOX_AUTH_FRONT_URL;

    return {
      userTypes,
      currentStep,
      currentPlan,
      form,
      loadingAfterPayment,
      type,
      businessType,
      handleInput,
      validateEmail,
      validateAccountFields,
      validateFreeAccountFields,
      nextStep,
      // acceptedThe,
      error,
      errors,
      setError,
      isUserFree,
      getTarget,
      source,
      signin_url,
    };
  },
};
</script>

<style scoped>
.btn {
  background-image: linear-gradient(90deg, #c026d3, #2563eb);
}
.title {
  display: flex;
  justify-content: center;
  letter-spacing: -2px;
}
.title__box {
  letter-spacing: -2px;
  color: #333;
}
.title__ad {
  color: #3652d9;
}
.error {
  @apply font-medium text-red-600 mt-1;
}
</style>
