<template>
  <div
    class="w-screen min-h-screen flex flex-col items-center"
    v-if="sendEmail == true"
  >
    <div
      class="flex flex-row p-8 text-center justify-center items-center content-center my-auto"
    >
      <div>
        <h2 class="text-2xl font-semibold mb-4 pl-4">
          <Icon name="Mail" class="w-12 h-12 text-green-600 mx-auto mb-4" />
          A message has been sent to your email
        </h2>
        <p>
          If the email doesn't show up soon, check your spam folder. We sent it
          from hello@meetadbox.com.
        </p>
      </div>
    </div>
    <footer class="w-full bg-gray-900 text-white flex py-6 px-20">
      <p class="mr-auto">
        © 2022 Adbox, all rights reserved.
        <a href="mailto:hello@meetadbox.com" target="_blank"
          >hello@meetadbox.com</a
        >
      </p>
      <a
        href="https://meetadbox.com/terms"
        target="_blank"
        class="font-semibold text-green-500 mr-4"
        >Terms of Service</a
      >
      <a
        href="https://meetadbox.com/privacy"
        target="_blank"
        class="font-semibold text-green-500"
        >Privacy Policy</a
      >
    </footer>
  </div>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
    v-if="sendEmail == false"
  >
    <div class="max-w-md w-full">
      <div class="form mt-8">
        <input type="hidden" name="remember" value="true" />
        <div>
          <div class="mb-4">
            <Label for="email">Email address</Label>
            <Input
              id="email"
              aria-label="Email address"
              name="email"
              type="text"
              required
              placeholder="Email address"
              v-model="email"
            />
          </div>
        </div>

        <div class="w-full flex justify-center items-center py-2" v-if="error">
          <div
            class="w-5 h-5 bg-red-600 grid place-content-center mr-2 rounded-md"
          >
            <Icon name="Close" class="w-3 h-3 text-white" />
          </div>
          <p class="text-red-600 font-medium leading-none">
            {{ error }}
          </p>
        </div>

        <div class="mt-6">
          <Button @click="signIn" block>
            Send email
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button";
import Input from "@/components/UI/Input";
import Label from "@/components/UI/Label";
import Icon from "@/components/UI/Icon";

import axios from "axios";

const ADBOX_API_URL = process.env.VUE_APP_ADBOX_API_URL;

export default {
  name: "ForgotPasswordEmail",
  components: { Button, Input, Label, Icon },
  data() {
    return {
      email: "",
      sendEmail: false,
    };
  },
  methods: {
    async signIn() {
      let email = this.email;

      await axios({
        method: "POST",
        url: `${ADBOX_API_URL}/forgot-password/send-email`,
        data: {
          email,
        },
      });

      this.sendEmail = true;
    },
  },
};
</script>
