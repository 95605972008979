<template>
  <button
    type="submit"
    class="group relative flex justify-center py-5 px-4 border border-transparent text-sm leading-3 font-medium rounded-md text-white bg-indigo-600 lg:text-lg hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
    :class="{'w-full': block}"
  >
    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
      <slot name="icon"/>
    </span>
        <slot/>
  </button>
</template>

<script>
export default {
  props: {
    block: Boolean
  }
};
</script>

<style></style>
