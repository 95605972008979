<template>
  <div :class="$attrs.class">
    <div
      :class="
        iconName
          ? 'mt-2.5 relative text-gray-400 focus-within:text-gray-600'
          : ''
      "
    >
      <div
        v-if="iconName"
        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
      >
        <Icon name="User" class="h-5 w-5" />
      </div>
      <input
        type="text"
        v-bind="$attrs"
        :class="
          iconName
            ? 'block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600'
            : 'appearance-none rounded-none relative block w-full px-5 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 lg:text-lg'
        "
        v-model="value"
      />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/UI/Icon";
import { computed } from "vue";
export default {
  inheritAttrs: false,
  components: { Icon },
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    iconName: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const value = computed({
      get() {
        return props.modelValue || "";
      },
      set(newValue) {
        emit("update:modelValue", newValue);
        emit("change", newValue);
      },
    });

    return { value };
  },
};
</script>

<style></style>
